<template>
  <div
    class="type-two"
    :style="'background-color:' + bgColor + ';' + 'color:' + textColor + ';'"
  >
    <div class="content">
      <h2 class="text-title" v-html="title" />
      <p class="text-subtitle" v-html="subtitle" />
      <v-avatar class="avatar">
        <v-img
          lazy-src="@/assets/images/lazy-image.png"
          :src="getAsset(imageSrc)"
        />
      </v-avatar>
      <p class="text-desc" v-html="description" />
    </div>
  </div>
</template>

<script>
export default {
  name: "TypeTwo",
  props: {
    bgColor: {
      type: String,
      default: "#fff"
    },
    textColor: {
      type: String,
      default: "#000"
    },
    imageSrc: {
      type: String,
      default: "images/ハンギングビル建設.webp"
    },
    title: {
      type: String,
      default: "title"
    },
    subtitle: {
      type: String,
      default: "subtitle"
    },
    description: {
      type: String,
      default: "description"
    }
  },
  methods: {
    getAsset(path) {
      return require("@/assets/" + path);
    }
  }
};
</script>

<style lang="sass" scoped>
@import '~vuetify/src/styles/styles.sass'
.type-two
  text-align: center
  padding: 12px
  .content
    margin: 0 auto
    max-width: 800px
    width: 100%
    padding: 0 12px
    .text-title, .text-subtitle, .avatar
      margin-bottom: 32px
  .avatar
    height: 300px !important
    width: 300px !important
@media #{map-get($display-breakpoints, 'sm-and-up')}
  .type-two
    padding: 12px
    .content
      .text-title, .text-subtitle, .avatar
        margin-bottom: 55px
      .avatar
        height: 400px !important
        width: 400px !important
</style>
