<template>
  <div class="d-flex justify-center">
    <v-btn
      aria-label="お問い合わせ"
      color="primary"
      rounded
      large
      class="mt-7 mt-sm-5"
      to="/#contact"
      @click="$vuetify.goTo(target, options)"
    >
      お問い合わせ
    </v-btn>
  </div>
</template>

<script>
import * as easings from "vuetify/es5/services/goto/easing-patterns";
export default {
  name: "GoToContact",
  data() {
    return {
      type: "number",
      selector: "#contact",
      duration: 1000,
      offset: 500,
      easing: "easeInOutCubic",
      easings: Object.keys(easings)
    };
  },
  computed: {
    target() {
      const value = this[this.type];
      if (!isNaN(value)) return Number(value);
      else return value;
    },
    options() {
      return {
        duration: this.duration,
        offset: this.offset,
        easing: this.easing
      };
    }
  }
};
</script>

<style scoped></style>
