<template>
  <v-container>
    <vue-headful
      title="会社メッセージ"
      description="Description here"
      keywords="明るい未来のためのエンジニアリング"
    />
    <type-two
      :title="companyMsg[0].title"
      :subtitle="companyMsg[0].subtitle"
      :description="companyMsg[0].description"
    />
    <go-to-contact />
  </v-container>
</template>

<script>
import TypeTwo from "../components/TypeTwo";
import GoToContact from "../components/GoToContact";
export default {
  name: "CompanyMessage",
  components: { GoToContact, TypeTwo },
  data() {
    return {
      companyMsg: [
        {
          bg_color: "#fff",
          text_color: "#000",
          image: "images/ハンギングビル建設.webp",
          title: "日本の建設技術を守る",
          subtitle: "私たちの使命",
          description:
            "我々JThink株式会社の建設現場支援サービスは、<br />新型コロナウイルスへの感染を防止する新たな働き方への挑戦です。　<br />" +
            "　<br />" +
            "<div class='text-left'>3密を防ぐための、<br />" +
            "・現場外から現場の様子を共有システムで繋げる CSRSシステム<br />" +
            "・熟練技術者・女性技術者・高齢技術者・障害を抱えた技術者が活躍できる リモート技術者支援サービス<br />" +
            "がWithコロナ時代の新しい働き方を強力にサポートいたします。</div>" +
            "<br />" +
            "これまでの完全現場主義から遠隔支援という働き方に向き合いながら、日本の高度な技術を承継し、日本の建設技術をさらに向上できるよう、JThinkは挑戦していきます。皆様の協力でこの新しい働き方を育てていきましょう。<br />" +
            "<br />" +
            "<div class='text-right'>JThink株式会社　玉里 芳直</div>"
        }
      ]
    };
  }
};
</script>

<style scoped></style>
